import { LoadingService } from '@/services/loading.service';
import { SessionService } from '@/services/session.service';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { tap, Observable } from 'rxjs';
import { IResult } from '../interfaces/result.interface';
import { SnackBarService } from './../services/snackbar.service';

@Injectable()
export class SuccessMessageInterceptor implements HttpInterceptor {
  constructor(
    protected dialog: MatDialog,
    protected sessionService: SessionService,
    protected loadingService: LoadingService,
    protected snackBarService: SnackBarService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event) => {
        const isResponse = event instanceof HttpResponse;
        if (!isResponse) return;

        if (!event.ok) return;

        if (!event?.body) return;

        const result = event.body as IResult<unknown>;

        if (!result.messages) return;

        result.messages.forEach((message) => {
          this.snackBarService.successMessage(message);
        });
      }),
    );
  }
}
