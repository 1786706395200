import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthenticateApi } from '@/api/authenticate.api';
import { Token, UserToken } from '@/models/token.model';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(
    private authenticateApi: AuthenticateApi,
    private router: Router,
  ) {}

  public async login(email: string, password: string): Promise<Token> {
    const token = await this.authenticateApi.login(email, password);
    if (token) {
      this.setToken(token);
    }
    return token;
  }

  public getUserToken(): UserToken | null {
    const token = this.getToken();
    if (token) {
      return this.jwtHelper.decodeToken(token.accessToken) as UserToken;
    }
    return null;
  }

  public setToken(token: Token): void {
    if (token) {
      localStorage.setItem(environment.sessionKey, JSON.stringify(token));
    } else {
      localStorage.removeItem(environment.sessionKey);
    }
  }

  public getToken(): Token | null {
    const t = localStorage.getItem(environment.sessionKey) || '';
    return t !== '' ? (JSON.parse(t) as Token) : null;
  }

  public Logged(): boolean {
    const t = localStorage.getItem(environment.sessionKey) || '';
    if (t === '' || t === null || typeof t === 'undefined') {
      return false;
    }
    return true;
  }

  public removeToken(): void {
    localStorage.removeItem(environment.sessionKey);
  }

  logout() {
    this.authenticateApi.logout();
    localStorage.clear();
    this.router.navigateByUrl('/auth/login');
  }
}
