import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from '@/services/theme.service';

@Component({
  selector: 'app-loading-full-screen',
  templateUrl: './loading-full-screen.component.html',
  styleUrls: ['./loading-full-screen.component.scss'],
})
export class LoadingFullScreenComponent implements OnInit {
  @Input() show = false;
  @Input() text = '';

  public bk = '';
  public color = '';

  constructor(public themeService: ThemeService) {
    this.themeService.themeChanged$.subscribe(() => {
      this.updateThema();
    });
  }

  ngOnInit(): void {
    this.updateThema();
  }

  updateThema(): void {
    if (this.themeService.isDarkMode()) {
      this.bk = 'rgba(0, 0, 0, 0.9)';
      this.color = '#FFF';
    } else {
      this.bk = 'rgba(255, 255, 255, 0.9)';
      this.color = '#000';
    }
  }
}
