import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '@/components/snack-bar/snack-bar.component';

@Injectable()
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  successMessage(message: string): void {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        type: 'success',
        message,
      },
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['snackBar', 'success'],
      duration: 5000,
    });
  }

  errorMessage(message: string): void {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        type: 'error',
        message,
      },
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['snackBar', 'error'],
      duration: 5000,
    });
  }

  dismiss(): void {
    this.snackBar.dismiss();
  }
}
