<div class="dialog">
  <h2 class="dialog__title" mat-dialog-title [innerHTML]="data.title"></h2>
  <div class="dialog__content" mat-dialog-content>
    <div [innerHTML]="data.content"></div>
  </div>
  <mat-divider class="dialog__divider"></mat-divider>
  <div mat-dialog-actions class="dialog__actions">
    <button class="dialog__cancel" mat-flat-button mat-dialog-close (click)="onCancel()">
      <strong>{{ data.leftButtonText }}</strong>
    </button>
    <button class="dialog__right-button" mat-flat-button mat-dialog-close (click)="onRightButton()">
      <strong>{{ data.rightButtonText }}</strong>
    </button>
  </div>
</div>
