import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public loadingEvent$: EventEmitter<string>;
  constructor() {
    this.loadingEvent$ = new EventEmitter();
  }

  show(text: string = ' '): void {
    this.loadingEvent$.emit(text);
  }

  hide(): void {
    this.loadingEvent$.emit('');
  }
}
