import { environment } from '@/environments/environment';
import { OptionsQuery } from '@/models/api.model';
import { ErrorsResult } from '@/models/errors-result.model';
import { Pagination } from '@/models/pagination.model';
import { LoadingService } from '@/services/loading.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseApi {
  readonly url: string = '';

  constructor(
    protected http: HttpClient,
    protected dialog: MatDialog,
    protected loadingService: LoadingService,
  ) {
    this.url = environment.baseURL;
  }

  protected async pagination<T>(
    url: string,
    pagination: Pagination<T>,
    params?: any,
  ): Promise<Pagination<T>> {
    const result = await this.get<Pagination<T>>(url, {
      params: {
        ...pagination.getPaginationToApi(),
        ...params,
      },
    });
    pagination.setPaginationFromApi(result);
    return pagination;
  }

  protected get<T>(url: string, options?: OptionsQuery | {}): Promise<T> {
    options = options || {};
    return lastValueFrom(this.http.get<T>(url, options));
  }

  protected post<T>(url: string, data: any): Promise<T> {
    const fullUrl = `${url}`;
    return lastValueFrom(this.http.post<T>(fullUrl, data));
  }

  protected put<T>(url: string, data: any): Promise<T> {
    return lastValueFrom(this.http.put<T>(url, data));
  }

  protected patch<T>(url: string, data: any): Promise<T> {
    return lastValueFrom(this.http.patch<T>(url, data));
  }

  protected delete<T>(url: string, data: any = {}): Promise<T> {
    return lastValueFrom(this.http.request<T>('DELETE', url, { body: data }));
  }

  protected handlerErrorsResult(errors: ErrorsResult): any {
    let message = '';

    errors._errors.forEach((p) => {
      message += `<div>${p}</div>`;
    });

    this.showModalError('Ops! um erro aconteceu.', message);
  }

  private showModalError(title: string, message: string): void {
    const dialogData = {
      title,
      text: `<div class="dialog-error-message">${message}</div>`,
      buttonText: 'Fechar',
      buttonType: '',
      showCancelOption: false,
    };

    this.dialog.open(DialogComponent, {
      width: '500px',
      data: dialogData,
    });

    this.loadingService.hide();
  }
}
