<h1 mat-dialog-title [innerHTML]="data.title"></h1>
<div mat-dialog-content>
  <div [innerHTML]="data.text"></div>
</div>

<div mat-dialog-actions class="pull-right">
  <button mat-flat-button *ngIf="data.showCancel" (click)="onNoClick()">FECHAR</button>
  <button mat-flat-button [mat-dialog-close]="true" [color]="data.typeButton" cdkFocusInitial>
    <mat-icon *ngIf="data.iconButton">
      {{ data.iconButton }}
    </mat-icon>
    {{ data.textButton }}
  </button>
</div>
