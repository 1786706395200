import { EventEmitter } from '@angular/core';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '@/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private renderer: Renderer2;
  private theme = '';
  public themeChanged$: EventEmitter<string>;

  constructor(rendererFactory: RendererFactory2) {
    this.themeChanged$ = new EventEmitter();
    this.renderer = rendererFactory.createRenderer(null, null);
    this.setTheme();
  }

  init(): void {
    this.renderer.addClass(document.body, this.theme);
  }

  update(theme: 'dark-mode' | 'light-mode'): void {
    this.setColorTheme(theme);
    const previousColorTheme =
      theme === 'dark-mode' ? 'dark-mode' : 'light-mode';
    this.renderer.removeClass(document.body, previousColorTheme);
    this.renderer.addClass(document.body, theme);
    this.themeChanged$.emit(theme);
  }

  isDarkMode(): boolean {
    return this.theme === 'dark-mode';
  }

  private setColorTheme(theme: 'dark-mode' | 'light-mode'): void {
    this.theme = theme;
    localStorage.setItem(environment.themeKey, theme);
  }

  private setTheme(): void {
    if (localStorage.getItem(environment.themeKey)) {
      this.theme = localStorage.getItem(environment.themeKey) || 'light-mode';
      this.theme === 'light-mode'
        ? this.update('light-mode')
        : this.update('dark-mode');
    } else {
      this.theme = 'light-mode';
      this.update('light-mode');
    }
  }
}
