<div class="content" *ngIf="data.type === 'success'">
  <mat-icon class="info"> info </mat-icon>

  <div class="message">
    <span class="text"> {{ data.message }}</span>
  </div>

  <button mat-icon-button (click)="snackBarRef.dismiss()">
    <mat-icon class="close">close </mat-icon>
  </button>
</div>

<div class="content" *ngIf="data.type === 'error'">
  <mat-icon class="info">error </mat-icon>

  <div class="message">
    <span class="text"> {{ data.message }}</span>
  </div>

  <button mat-icon-button (click)="snackBarRef.dismiss()">
    <mat-icon class="close">close </mat-icon>
  </button>
</div>
