import { SnackBarService } from './../services/snackbar.service';
import {
  ErrorDialogComponent,
  ErrorDialogData,
} from '@/components/error-dialog/error-dialog.component';
import { LoadingService } from '@/services/loading.service';
import { SessionService } from '@/services/session.service';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorHandler implements HttpInterceptor {
  constructor(
    protected dialog: MatDialog,
    protected sessionService: SessionService,
    protected loadingService: LoadingService,
    protected snackBarService: SnackBarService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        let mensagem = '';
        let titulo = 'Um erro aconteceu';
        if (err.status === 401) {
          this.sessionService.logout();
        }

        // TODO: Verificar mensagens de erros
        if (err.status === 400) {
          console.error('An error occurred:', err.error.messages);

          if (Array.isArray(err.error.messages)) {
            err.error.messages.forEach((e: string) => {
              this.snackBarService.errorMessage(e);
            });
          }
        }

        if (err.status === 500) {
          console.error('An error occurred:', err.error.Message);
          mensagem = err.errors.Message;
          this.geraModalDeErro(titulo, mensagem);
        }

        if (err.error instanceof ErrorEvent) {
          console.error('An error occurred:', err.error.Message);
          mensagem = err.error.Message;
          this.geraModalDeErro(titulo, mensagem);
        }

        const error = err.error || err.statusText;
        return throwError(error);
      }),
    );
  }

  private geraModalDeErro(titulo: string, mensagem: string): void {
    const dialogData: ErrorDialogData = {
      title: titulo,
      text: `<div class="dialog-error-message">${mensagem}</div>`,
      textButton: 'Fechar',
      typeButton: '',
      iconButton: '',
      showCancel: false,
    };

    this.dialog.open(ErrorDialogComponent, {
      width: '500px',
      data: dialogData,
    });

    this.loadingService.hide();
  }
}
