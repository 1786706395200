import { LoadingService } from '@/services/loading.service';
import { ThemeService } from '@/services/theme.service';
import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  template: `<app-loading-full-screen [show]="show" [text]="text">
    </app-loading-full-screen>
    <router-outlet> </router-outlet>`,
})
export class AppComponent {
  public show = false;
  public text = '';

  constructor(
    private loadingService: LoadingService,
    private themeService: ThemeService,
  ) {
    this.themeService.init();

    this.loadingService.loadingEvent$.subscribe((item: string) => {
      if (item !== '') {
        this.show = true;
        this.text = item;
      } else {
        this.show = false;
        this.text = '';
      }
    });
  }
}
